'use client';

import BaseTooltip from 'rc-tooltip';
import React, { Suspense } from 'react';

/*
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}*/

interface Props {
  direction?: 'top' | 'bottom' | 'left' | 'right';
  tooltip: React.ReactNode;
  width?: number;
  onVisibleChange?: (visible: boolean) => void;
}

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  direction,
  tooltip,
  onVisibleChange,
  width,
}) => (
  <Suspense>
    <BaseTooltip
      placement={direction}
      overlay={tooltip}
      trigger={['hover', 'click', 'focus']}
      onVisibleChange={onVisibleChange}
    >
      <span>{children}</span>
    </BaseTooltip>
  </Suspense>
);

export default Tooltip;
