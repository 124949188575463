import { getSharedQueryClient } from 'common-nextjs';
import {
  ApiPaginatedResponse,
  fetcher,
  getBaseUrl,
  makeFetcher,
} from 'fetcher-session';
import { NextPageContext } from 'next';
import { RailsWebConfiguration } from '~/typings/services/rails/configuration';
import {
  RailsFacetItemMeta,
  RailsFacetQueryOptions,
  RailsFlattenedFacetQuery,
} from '~/typings/services/rails/facets';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import {
  RailsFacetMeta,
  RailsFacetResponse,
} from '~/typings/services/rails/responses';

export async function fetchFacetItems<T extends RailsFacetQueryOptions>(
  this: NextPageContext | undefined | void,
  query: T,
): Promise<
  T['include_meta'] extends 0 | undefined
    ? ApiPaginatedResponse<RailsThumbnailItem[], unknown>
    : T['include_data'] extends 0 | undefined
      ? RailsFacetMeta
      : RailsFacetResponse
> {
  const sharedQueryClient = getSharedQueryClient(this);
  const configuration = sharedQueryClient.getQueryData<RailsWebConfiguration>([
    'configuration',
  ]);

  const response = await fetcher<RailsFacetResponse>(
    configuration?.v3_facet_items == true
      ? '/v3/facet_items'
      : '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      dataOnly: false,
      params: query,
      authHeader: !!query.favorited || query.context === 'locker_owner',
      headers:
        query.ignore_cache || query.no_cache
          ? { 'Cache-Control': 'no-cache', pragma: 'no-cache' }
          : undefined,
    },
  );

  if (query.include_data == 0) {
    return response.meta as any;
  } else if (query.include_meta == 0) {
    return {
      data: response.data,
      meta: {
        paging: response.meta.paging,
      },
    } as any;
  } else {
    return response as any;
  }
}

export function fetchUserFavorites(
  this: NextPageContext | undefined | void,
  params: RailsFacetQueryOptions,
) {
  return fetchFacetItems.call(this, {
    page_size: 48,
    ...params,
    favorited: 1,
    sort: 'newest',
  });
}

export const fetchMultipleItemsByIds = (itemIds: number[]) =>
  fetchFacetItems({
    include_ids: itemIds.join(','),
    include_meta: 0,
    state: ['available'],
  });

export const fetchFacetMetaNoItems = (query: RailsFacetQueryOptions) =>
  fetchFacetItems({
    ...query,
    include_data: 0,
  });

export const fetchFacetUserMeta = (
  itemIds: number[],
  additionalParams?: RailsFlattenedFacetQuery,
  context?: 'locker' | 'results',
) =>
  fetcher<RailsFacetItemMeta[]>('/v2/facet_items/meta', {
    params: {
      ...additionalParams,
      context,
      include_ids: itemIds.join(','),
      page_size: itemIds.length,
    },
  });

export const fetchFacetItemsNoMeta = (query?: RailsFacetQueryOptions | null) =>
  fetchFacetItems({
    ...query,
    include_meta: 0,
  });

export const fetchLockerItemsMeta = makeFetcher<
  RailsFacetItemMeta[],
  [itemIds: string, username: string, pageSize: number]
>((itemIds: string, username: string, pageSize: number) => [
  '/v2/facet_items/meta',
  {
    params: {
      include_ids: itemIds,
      page_size: pageSize,
      seller: [username],
      src: 'locker',
    } as RailsFacetQueryOptions,
  },
]);

export const fetchRelatedModelItems = (item: RailsItem, pageSize = 6) =>
  fetchFacetItems({
    page_size: pageSize,
    include_meta: 0,
    model: item.model && [item.model.id],
    category: [item.categories[0].id],
    similar_to: item.id,
  });

export const fetchRelatedItems = (item: RailsItem, count = 12) =>
  fetchFacetItems({
    page_size: count,
    category: [item.categories[0].id],
    similar_to: item.id,
    include_meta: 0,
  });

export interface RailsItemRemoveReasons {
  slug: string;
  label: string;
}

export const fetchItemRemoveReasons = () =>
  fetcher<RailsItemRemoveReasons[]>('/v1/items/remove_reasons');
