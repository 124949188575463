'use client';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import React, { ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { CSSProp } from 'styled-components';

export interface InputOnlyProps {
  error?: boolean | string;
  fullWidth?: boolean;
  inputClassName?: string;
  inputCss?: CSSProp;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  ref?: React.RefObject<HTMLInputElement | null>;
}

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> &
  InputOnlyProps & {
    css?: CSSProp;
    innerRef?: any; // passed by formik sometimes
    size?: 'large';
  };

export function Input({
  children,
  className,
  css,
  disabled,
  error,
  fullWidth,
  innerRef,
  inputClassName,
  inputCss,
  leftAdornment,
  onClick,
  ref,
  rightAdornment,
  size,
  style,
  ...props
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => inputRef.current!, []);
  useImperativeHandle(innerRef, () => inputRef.current!, []);

  function handleContainerClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!e.isDefaultPrevented() && inputRef?.current) {
      inputRef.current.focus();
    }
  }

  return (
    <div
      className={clsx(
        'flex h-10 flex-grow items-center rounded border transition duration-200 focus-within:ring',
        'dark:border-grey-700 dark:bg-grey-900 dark:text-grey-300',
        {
          'w-full': fullWidth,
          'border-red-500 bg-white ring-red-500 ring-opacity-30': error,
          'bg-white focus-within:border-green-500': !error,
          'text-turf-green-500': !disabled,
          'bg-off-white-500 text-slate-green-500 cursor-not-allowed': disabled,
        },
        className,
      )}
      css={css}
      onClick={handleContainerClick}
      style={style}
    >
      {leftAdornment}

      <input
        css={inputCss}
        disabled={disabled}
        className={clsx(
          'placeholder-slate-green-500 h-full w-full appearance-none rounded border-transparent bg-transparent outline-none disabled:cursor-not-allowed',
          leftAdornment && rightAdornment
            ? 'px-2'
            : leftAdornment
              ? 'pl-2 pr-4'
              : rightAdornment
                ? 'pl-4 pr-2'
                : 'px-4',
          inputClassName,
          {
            'text-base': !size,
            'text-lg': size === 'large',
          },
        )}
        {...props}
        aria-invalid={error ? true : undefined}
        ref={inputRef}
      >
        {children}
      </input>

      {rightAdornment}
    </div>
  );
}

export function PasswordInput(
  props: Omit<InputProps, 'type' | 'rightAdornment'>,
) {
  const [visible, setVisible] = useState(false);

  return (
    <Input
      {...props}
      type={visible ? 'text' : 'password'}
      rightAdornment={
        <button
          type="button"
          className="-m-2 p-2"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <EyeIcon className="mr-2 h-6 w-6" />
          ) : (
            <EyeSlashIcon className="mr-2 h-6 w-6" />
          )}
        </button>
      }
    />
  );
}
