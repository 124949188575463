import { QueryClient } from '@tanstack/react-query';
import { NextPageContext } from 'next';

export function getSharedQueryClient(
  ctx?: NextPageContext | void,
): QueryClient {
  if (typeof window !== 'undefined') {
    if (!window['__sls_reactQueryClient']) {
      window['__sls_reactQueryClient'] = new QueryClient();
    }

    return window['__sls_reactQueryClient'];
  }

  // @ts-ignore
  return ctx?.__queryClient || new QueryClient();
}
